import {
  REQUEST_LIST_REQUEST,
  REQUEST_LIST_SUCCESS,
  REQUEST_LIST_FAIL,
  REQUEST_LIST_MY_REQUEST_CHAMPION,
  REQUEST_LIST_MY_SUCCESS_CHAMPION,
  REQUEST_LIST_MY_FAIL_CHAMPION,
  REQUEST_LIST_MY_REQUEST_INITIATOR,
  REQUEST_LIST_MY_SUCCESS_INITIATOR,
  REQUEST_LIST_MY_FAIL_INITIATOR,
  REQUEST_LIST_MY_REQUEST_USER,
  REQUEST_LIST_MY_SUCCESS_USER,
  REQUEST_LIST_MY_FAIL_USER,
  REQUEST_LIST_MY_REQUEST_TASKHOLDER,
  REQUEST_LIST_MY_SUCCESS_TASKHOLDER,
  REQUEST_LIST_MY_FAIL_TASKHOLDER,
  REQUEST_DETAILS_REQUEST,
  REQUEST_DETAILS_SUCCESS,
  REQUEST_DETAILS_FAIL,
  REQUEST_DETAILS_RESET,
  REQUEST_DELETE_REQUEST,
  REQUEST_DELETE_SUCCESS,
  REQUEST_DELETE_FAIL,
  REQUEST_CREATE_RESET,
  REQUEST_CREATE_FAIL,
  REQUEST_CREATE_SUCCESS,
  REQUEST_CREATE_REQUEST,
  REQUEST_UPDATE_REQUEST,
  REQUEST_UPDATE_SUCCESS,
  REQUEST_UPDATE_FAIL,
  REQUEST_UPDATE_RESET,
  REQUEST_APPROVE_FAIL,
  REQUEST_APPROVE_SUCCESS,
  REQUEST_APPROVE_REQUEST,
  REQUEST_APPROVE_RESET,
  REQUEST_WITHDRAW_FAIL,
  REQUEST_WITHDRAW_SUCCESS,
  REQUEST_WITHDRAW_REQUEST,
  REQUEST_WITHDRAW_RESET,
  REQUEST_COMMENTS_FAIL,
  REQUEST_COMMENTS_SUCCESS,
  REQUEST_COMMENTS_REQUEST,
  REQUEST_COMMENTS_RESET,
  REQUEST_SEND_MAIL_FAIL,
  REQUEST_SEND_MAIL_SUCCESS,
  REQUEST_SEND_MAIL_REQUEST,
} from '../constants/requestConstants';

export const requestListReducer = (state = { requests: [] }, action) => {
  switch (action.type) {
    case REQUEST_LIST_REQUEST:
      return { loading: true, requests: [] };
    case REQUEST_LIST_SUCCESS:
      return {
        loading: false,
        requests: action.payload.requests,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case REQUEST_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const requestListMyReducerAsInitiator = (
  state = { requests: [] },
  action
) => {
  switch (action.type) {
    case REQUEST_LIST_MY_REQUEST_INITIATOR:
      return { loading: true, requests: [] };
    case REQUEST_LIST_MY_SUCCESS_INITIATOR:
      return {
        loading: false,
        requests: action.payload.requests,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case REQUEST_LIST_MY_FAIL_INITIATOR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const requestListMyReducerAsChampion = (
  state = { requests: [] },
  action
) => {
  switch (action.type) {
    case REQUEST_LIST_MY_REQUEST_CHAMPION:
      return { loading: true, requests: [] };
    case REQUEST_LIST_MY_SUCCESS_CHAMPION:
      return {
        loading: false,
        requests: action.payload.requests,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case REQUEST_LIST_MY_FAIL_CHAMPION:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const requestListMyReducerAsUser = (
  state = { requests: [] },
  action
) => {
  switch (action.type) {
    case REQUEST_LIST_MY_REQUEST_USER:
      return { loading: true, requests: [] };
    case REQUEST_LIST_MY_SUCCESS_USER:
      return {
        loading: false,
        requests: action.payload.requests,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case REQUEST_LIST_MY_FAIL_USER:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const requestListMyReducerAsTaskholder = (
  state = { requests: [] },
  action
) => {
  switch (action.type) {
    case REQUEST_LIST_MY_REQUEST_TASKHOLDER:
      return { loading: true, requests: [] };
    case REQUEST_LIST_MY_SUCCESS_TASKHOLDER:
      return {
        loading: false,
        requests: action.payload.requests,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case REQUEST_LIST_MY_FAIL_TASKHOLDER:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const requestDetailsReducer = (
  state = { request: { filesAttached: [] } },
  action
) => {
  switch (action.type) {
    case REQUEST_DETAILS_REQUEST:
      return { ...state, loading: true };
    case REQUEST_DETAILS_SUCCESS:
      return { loading: false, request: action.payload };
    case REQUEST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case REQUEST_DETAILS_RESET:
      return { request: { filesAttached: [] } };
    default:
      return state;
  }
};

export const requestDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_DELETE_REQUEST:
      return { loading: true };
    case REQUEST_DELETE_SUCCESS:
      return { loading: false, success: true };
    case REQUEST_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const requestCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_CREATE_REQUEST:
      return { loading: true };
    case REQUEST_CREATE_SUCCESS:
      return { loading: false, success: true, request: action.payload };
    case REQUEST_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case REQUEST_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const requestUpdateReducer = (state = { request: {} }, action) => {
  switch (action.type) {
    case REQUEST_UPDATE_REQUEST:
      return { loading: true };
    case REQUEST_UPDATE_SUCCESS:
      return { loading: false, success: true, request: action.payload };
    case REQUEST_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case REQUEST_UPDATE_RESET:
      return { request: {} };
    default:
      return state;
  }
};

export const requestSendMailReducer = (
  state = { mailResponse: {} },
  action
) => {
  switch (action.type) {
    case REQUEST_SEND_MAIL_REQUEST:
      return { loading: true };
    case REQUEST_SEND_MAIL_SUCCESS:
      return { loading: false, success: true, mailResponse: action.payload };
    case REQUEST_SEND_MAIL_FAIL:
      return { loading: false, error: action.payload };
    case REQUEST_UPDATE_RESET:
      return { mailResponse: {} };
    default:
      return state;
  }
};

export const requestApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_APPROVE_REQUEST:
      return {
        loading: true,
      };
    case REQUEST_APPROVE_SUCCESS:
      return {
        loading: false,
        success: true,
        requestDetails: action.payload
      };
    case REQUEST_APPROVE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case REQUEST_APPROVE_RESET:
      return {};
    default:
      return state;
  }
};

export const requestWithdrawReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_WITHDRAW_REQUEST:
      return {
        loading: true,
      };
    case REQUEST_WITHDRAW_SUCCESS:
      return {
        loading: false,
        success: true,
        requestDetails: action.payload
      };
    case REQUEST_WITHDRAW_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case REQUEST_WITHDRAW_RESET:
      return {};
    default:
      return state;
  }
};

export const requestCommentsReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_COMMENTS_REQUEST:
      return {
        loading: true,
      };
    case REQUEST_COMMENTS_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case REQUEST_COMMENTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case REQUEST_COMMENTS_RESET:
      return {};
    default:
      return state;
  }
};
