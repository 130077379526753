import { getState } from "../store";
import { GroupEditScreen, GroupListScreen, HomeScreen, InviteUserScreen, ProductEditScreen, ProductListScreen, ProfileScreen, RequestEditScreen, RequestListAdminScreen, RequestListChampionScreen, RequestListInitiatorScreen, RequestListTaskholderScreen, RequestListUserScreen, RequestViewScreen, UserEditScreen, UserListScreen } from "../screens";


export const VALID_EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export const REQUEST_CONFIG = () => {
    const { userInfo } = getState().userLogin;
    return {
        headers: {
           "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo?.token}`,
        },
    }
};


export const PROTECTED_ROUTES = [
    { path: "/", component: HomeScreen, exact: true },
    { path: "/invite", component: InviteUserScreen },
    { path: "/profile", component: ProfileScreen },
    { path: "/requestlistAsInitiator", component: RequestListInitiatorScreen },
    { path: "/requestlistAsChampion", component: RequestListChampionScreen },
    { path: "/requestlistAsUser", component: RequestListUserScreen },
    { path: "/requestlistAsTaskholder", component: RequestListTaskholderScreen },
    { path: "/request/:id/edit", component: RequestEditScreen },
    { path: "/request/:id/view", component: RequestViewScreen },
    { path: "/admin/userlist", component: UserListScreen },
    { path: "/admin/user/:id/edit", component: UserEditScreen },
    { path: "/admin/productlist", component: ProductListScreen },
    { path: "/admin/product/:id/edit", component: ProductEditScreen },
    { path: "/admin/grouplist", component: GroupListScreen },
    { path: "/admin/group/:id/edit", component: GroupEditScreen },
    { path: "/admin/requestlist", component: RequestListAdminScreen },
];