import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  requestListReducer,
  requestListMyReducerAsChampion,
  requestListMyReducerAsInitiator,
  requestListMyReducerAsUser,
  requestListMyReducerAsTaskholder,
  requestDetailsReducer,
  requestDeleteReducer,
  requestCreateReducer,
  requestUpdateReducer,
  requestApproveReducer,
  requestWithdrawReducer,
  requestSendMailReducer,
  requestCommentsReducer,
} from './reducers/requestReducers';
import {
  productListReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productDetailsReducer,
} from './reducers/productReducers';
import {
  groupListReducer,
  groupDeleteReducer,
  groupCreateReducer,
  groupUpdateReducer,
  groupDetailsReducer,
} from './reducers/groupReducers';
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userListForEcrReducer,
  userDeleteReducer,
  userUpdateReducer,
} from './reducers/userReducers';
import { appReducer } from './reducers/appReducers';
import appState from './constants/appState';

const reducer = combineReducers({
  requestList: requestListReducer,
  requestListMyInitiator: requestListMyReducerAsInitiator,
  requestListMyChampion: requestListMyReducerAsChampion,
  requestListMyTaskholder: requestListMyReducerAsTaskholder,
  requestListMyUser: requestListMyReducerAsUser,
  requestDetails: requestDetailsReducer,
  requestDelete: requestDeleteReducer,
  requestCreate: requestCreateReducer,
  requestUpdate: requestUpdateReducer,
  requestApprove: requestApproveReducer,
  requestWithdraw: requestWithdrawReducer,
  requestComment: requestCommentsReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userListForEcr: userListForEcrReducer,
  productList: productListReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productDetails: productDetailsReducer,
  groupList: groupListReducer,
  groupDelete: groupDeleteReducer,
  groupCreate: groupCreateReducer,
  groupUpdate: groupUpdateReducer,
  groupDetails: groupDetailsReducer,
  requestMail: requestSendMailReducer,
  app: appReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const initialState = {
  // ...appState,
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

export const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

window.store = store;

export const { dispatch, getState } = store;
