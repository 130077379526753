import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listMyRequestsAsUser } from "../actions/requestActions";
import { Link } from "react-router-dom";
import { Row, Col, Button, Container } from 'react-bootstrap';

const HomeScreen = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  useEffect(() => {
    if (userInfo)
      dispatch(listMyRequestsAsUser());
  }, [userInfo]);
  const { requests } = useSelector(state => state.requestListMyUser);

  const requestsInfo = [
    {
      name: `All Open ECR's`,
      count: 0,
      link: "/requestlistAsUser",
    },
    {
      name: `Initial Approval`,
      count: 0,
      link: "/admin/requestlist/initiated",
    },
    {
      name: `Implementation task list`,
      count: 0,
      link: "/admin/requestlist/tasklist",
    },
    {
      name: `Cert.Approval`,
      count: 0,
      link: "/admin/requestlist/certification",
    },
    {
      name: `Implementation Approval`,
      count: 0,
      link: "/admin/requestlist/implementation",
    },
    {
      name: `Post Implementation (Closed ECR's)`,
      count: 0,
      link: "/admin/requestlist/postimplement",
    },
  ];

  // [open_ecrs, intial approval, implementation_task_list, certification, implementation approval, closed_ecrs]
  (requests ?? []).forEach(({ progress: { currentStep }, isSubmitted }) => {
    if (isSubmitted) {
      requestsInfo[0].count++;
    }
    requestsInfo[currentStep].count++;
  });
  requestsInfo[0].count = requestsInfo[0].count - requestsInfo[5].count; // for open ecr's

  const today = new Date();
  const currentDayOfWeek = today.getDay();
  const daysUntilNextMonday = (1 + 7 - currentDayOfWeek) % 7;
  const daysUntilLastMonday = (6 + currentDayOfWeek) % 7;
  const nextMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + daysUntilNextMonday);;
  const lastMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - daysUntilLastMonday);;

  return (
    <Container>
      <Row className="my-3">
        <Col>
          <h2>ECR Dashboard</h2>
        </Col>
        <Col className="text-right">
          <a href="/assets/ECR - FAQ's_Unirac.pdf" target="_blank" rel="noopener noreferrer download">
            <Button>
              GET TO KNOW THE PORTAL
            </Button>
          </a>
        </Col>
      </Row>
      <table id="homeScreen">
        <thead>
          <tr>{requestsInfo.map(({ name, link }, index) => <th key={index}><Link to={link}>{name}<i className="fas fa-external-link-alt"></i></Link></th>)}</tr>
        </thead>
        <tbody>
          <tr>
            {requestsInfo.map(({ count }, index) => <td key={index}>{count}</td>)}
          </tr>
        </tbody>
      </table>
      <Row className="my-3">
        <Col>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <a href="/assets/ecrs.csv" target="_blank" rel="noopener noreferrer download">
            <Button>
              ECR Report
            </Button>
          </a>
        </Col>
      </Row>
      <p>ECR Aging Report is generated every Monday at 08:00 AM.</p>
      <p>Last Generated Report: {lastMonday.toLocaleDateString('en-US')} 08:00 AM.</p>
      <p>Next Generated Report: {nextMonday.toLocaleDateString('en-US')} 08:00 AM.</p>
    </Container>
  );
};
export default HomeScreen;