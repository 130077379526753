import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const RequestPhasesAdmin = () => {
  return (
    <Container>
      <Navbar
        variant="dark"
        style={{
          padding: '10px',
          backgroundColor: '#0083c2',
        }}
      >
        <Navbar.Brand href="/requestlistAsUser">Phase</Navbar.Brand>
        <Nav className="mr-auto ml-auto">
          <LinkContainer to="/admin/requestlist/initiated">
            <Nav.Link>Initiation Approval</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/admin/requestlist/tasklist">
            <Nav.Link>Implementation Task List</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/admin/requestlist/certification">
            <Nav.Link>Certification Approval</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/admin/requestlist/implementation">
            <Nav.Link>Implementation Approval</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/admin/requestlist/postimplement">
            <Nav.Link>Post-Implementation</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar>
    </Container>
  );
};

export default RequestPhasesAdmin;
