import axios from "axios";
import { VALID_EMAIL_REGEX, REQUEST_CONFIG as config } from "../constants/constants";
import {
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_FOR_ECR_FAIL,
  USER_LIST_FOR_ECR_REQUEST,
  USER_LIST_FOR_ECR_RESET,
  USER_LIST_FOR_ECR_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from "../constants/userConstants";
import { getState, dispatch } from "../store";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { data } = await axios.post(
      "/api/users/login",
      { email, password },
      config()
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: USER_LIST_RESET });
  dispatch({ type: USER_LIST_FOR_ECR_RESET });
  document.location.href = "/login";
};

export const register = (name, email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const { data } = await axios.post(
      "/api/users",
      { name, email, password },
      config()
    );

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`/api/users/${id}`, config());

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const { data } = await axios.put(`/api/users/profile`, user, config());

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    });
  }
};

export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const { data } = await axios.get(`/api/users`, config());

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    });
  }
};

export const listUsersForEcr = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_FOR_ECR_REQUEST,
    });

    const { data } = await axios.get(`/api/users/userList`, config());

    dispatch({
      type: USER_LIST_FOR_ECR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_LIST_FOR_ECR_FAIL,
      payload: message,
    });
  }
};

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    await axios.delete(`/api/users/${id}`, config());

    dispatch({ type: USER_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const { status } = await axios.put(`/api/users/${user._id}`, user, config());

    if (status === 200) {
      dispatch({ type: USER_UPDATE_SUCCESS });
      dispatch({ type: USER_DETAILS_SUCCESS, user });
      localStorage.setItem("userInfo", JSON.stringify(user));

      dispatch({ type: USER_DETAILS_RESET });
    } else throw "Unable to update user!";
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const forgotPassword = async (email) => {
  if (!email.match(VALID_EMAIL_REGEX)) throw "Invalid Email ID!";

  const {
    data: { success, error },
  } = await axios.post("/api/users/forgot-password", { email }, config());

  if (error) throw error;

  return success;
};

export const resetPassword = async (password, token) => {
  const { data, error } = await axios.post(
    "/api/users/reset-password/",
    { password, token },
    config()
  );
  if (error) throw error;
  return data;
};

export const inviteUsersAction = async (inviteEmails) => {
  const { data, error } = await axios.post(
    "/api/users/invite-users/",
    { inviteEmails },
    config()
  );
  if (error) throw error;
  return data;
};

export const sendVerifyEmailAction = async () => {
  const { status, data } = await axios.get("/api/users/verify-email", config());
  let { userInfo } = getState().userLogin;

  if (data === "activate user") {
    userInfo = { ...userInfo, active: true };
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: userInfo });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: userInfo,
    });
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }
  else if (status === 200) return "Verification Email Sent Successfully!";
  else return "Verification Email couldn't be sent!";
};

export const activateAccount = async (token) => {
  const { status } = await axios.post("/api/users/activate-account", { token }, config());
  let { userInfo } = getState().userLogin;

  if (status === 200) {
    if (userInfo?.email) {
      userInfo = { ...userInfo, active: true };
      dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: userInfo });
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: userInfo,
      });
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }
    else
      document.location.href = "/login";
  }
  else {
    dispatch({ type: USER_UPDATE_PROFILE_FAIL });
    return "Link has been expired. Please click on verify again";
  }
};