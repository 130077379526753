import axios from 'axios';
import {
  REQUEST_LIST_REQUEST,
  REQUEST_LIST_SUCCESS,
  REQUEST_LIST_FAIL,
  REQUEST_LIST_MY_REQUEST_INITIATOR,
  REQUEST_LIST_MY_SUCCESS_INITIATOR,
  REQUEST_LIST_MY_FAIL_INITIATOR,
  REQUEST_LIST_MY_REQUEST_CHAMPION,
  REQUEST_LIST_MY_SUCCESS_CHAMPION,
  REQUEST_LIST_MY_FAIL_CHAMPION,
  REQUEST_DETAILS_REQUEST,
  REQUEST_DETAILS_SUCCESS,
  REQUEST_DETAILS_FAIL,
  REQUEST_DELETE_SUCCESS,
  REQUEST_DELETE_REQUEST,
  REQUEST_DELETE_FAIL,
  REQUEST_CREATE_REQUEST,
  REQUEST_CREATE_SUCCESS,
  REQUEST_CREATE_FAIL,
  REQUEST_UPDATE_REQUEST,
  REQUEST_UPDATE_SUCCESS,
  REQUEST_UPDATE_FAIL,
  REQUEST_APPROVE_REQUEST,
  REQUEST_APPROVE_SUCCESS,
  REQUEST_APPROVE_FAIL,
  REQUEST_WITHDRAW_REQUEST,
  REQUEST_WITHDRAW_SUCCESS,
  REQUEST_WITHDRAW_FAIL,
  REQUEST_COMMENTS_REQUEST,
  REQUEST_COMMENTS_SUCCESS,
  REQUEST_COMMENTS_FAIL,
  REQUEST_SEND_MAIL_REQUEST,
  REQUEST_SEND_MAIL_SUCCESS,
  REQUEST_SEND_MAIL_FAIL,
  REQUEST_LIST_MY_SUCCESS_USER,
  REQUEST_LIST_MY_FAIL_USER,
  REQUEST_LIST_MY_REQUEST_USER,
  REQUEST_LIST_MY_SUCCESS_TASKHOLDER,
  REQUEST_LIST_MY_FAIL_TASKHOLDER,
  REQUEST_LIST_MY_REQUEST_TASKHOLDER,
} from '../constants/requestConstants';
import { logout } from './userActions';
import { store } from '../store';

export const listRequests =
  (keyword = '', pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/requests?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );

      dispatch({
        type: REQUEST_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REQUEST_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      if (error.message === 'Not authorized, token failed') {
        dispatch(logout());
      }
    }
  };

export const listMyRequestsAsInitiator =
  (keyword = '', pageNumber = '', pageSize = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST_LIST_MY_REQUEST_INITIATOR });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/requests/myrequestsAsInitiator?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        config
      );

      dispatch({
        type: REQUEST_LIST_MY_SUCCESS_INITIATOR,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REQUEST_LIST_MY_FAIL_INITIATOR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      if (error.message === 'Not authorized, token failed') {
        dispatch(logout());
      }
    }
  };

export const listMyRequestsAsChampion =
  (keyword = '', pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST_LIST_MY_REQUEST_CHAMPION });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/requests/myrequestsAsChampion?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );

      dispatch({
        type: REQUEST_LIST_MY_SUCCESS_CHAMPION,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REQUEST_LIST_MY_FAIL_CHAMPION,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      if (error.message === 'Not authorized, token failed') {
        dispatch(logout());
      }
    }
  };

  export const listMyRequestsAsUser =
  (keyword = '', pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST_LIST_MY_REQUEST_USER });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/requests/myrequestsAsUser?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );

      dispatch({
        type: REQUEST_LIST_MY_SUCCESS_USER,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REQUEST_LIST_MY_FAIL_USER,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      if (error.message === 'Not authorized, token failed') {
        dispatch(logout());
      }
    }
  };

  export const listMyRequestsAsTaskholder =
  (keyword = '', pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST_LIST_MY_REQUEST_TASKHOLDER });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/requests/myrequestsAsTaskholder?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );

      dispatch({
        type: REQUEST_LIST_MY_SUCCESS_TASKHOLDER,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REQUEST_LIST_MY_FAIL_TASKHOLDER,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      if (error.message === 'Not authorized, token failed') {
        dispatch(logout());
      }
    }
  };

export const listRequestDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REQUEST_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/requests/${id}`, config);

    dispatch({
      type: REQUEST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REQUEST_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    if (error.message === 'Not authorized, token failed') {
      dispatch(logout());
    }
  }
};

export const deleteRequest = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REQUEST_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/requests/${id}`, config);

    dispatch({
      type: REQUEST_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: REQUEST_DELETE_FAIL,
      payload: message,
    });
  }
};

export const createRequest = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: REQUEST_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/requests`, {}, config);

    dispatch({
      type: REQUEST_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: REQUEST_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateRequest = async (request, requestId) => {
  console.log('updateRequest');
  const { dispatch, getState } = store;
  try {
    dispatch({
      type: REQUEST_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data, status, ...prop } = await axios.put(
      `/api/requests/${requestId}`,
      request,
      config
    );
    console.log(data, status, prop);

    dispatch({
      type: REQUEST_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: REQUEST_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: REQUEST_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const sendMailRequest = (request) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REQUEST_SEND_MAIL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const data = await axios.post(
      `/api/requests/sendmail`,
      request,
      config
    );
    console.log(data);

    dispatch({
      type: REQUEST_SEND_MAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: REQUEST_SEND_MAIL_FAIL,
      payload: message,
    });
  }
};

export const approveRequest = (request) => async (dispatch, getState) => {
  console.log('approveRequest');
  try {
    dispatch({
      type: REQUEST_APPROVE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data, status, ...prop } = await axios.put(
      `/api/requests/${request._id}/approveRequest`,
      request,
      config
    );
    console.log(data, status, prop);

    dispatch({
      type: REQUEST_APPROVE_SUCCESS,
      payload: data.request,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: REQUEST_APPROVE_FAIL,
      payload: message,
    });
  }
};

export const withdrawRequest = (request) => async (dispatch, getState) => {
  console.log('withdrawRequest');
  try {
    dispatch({
      type: REQUEST_WITHDRAW_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data, status, ...prop } = await axios.put(
      `/api/requests/${request._id}/withdrawRequest`,
      request,
      config
    );
    console.log(data, status, prop);

    dispatch({
      type: REQUEST_WITHDRAW_SUCCESS,
      payload: data.request,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: REQUEST_WITHDRAW_FAIL,
      payload: message,
    });
  }
};

export const commentRequest = (request) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REQUEST_COMMENTS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/requests/${request._id}/addCommentsToRequest`,
      request,
      config
    );

    dispatch({
      type: REQUEST_COMMENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: REQUEST_COMMENTS_FAIL,
      payload: message,
    });
  }
};
